body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  width: 100vw;
  height: 100vh;
  font-size: 15px;
  font-weight: 700;
  color: #0d1136;
}

#root {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
}

.main {
  width: 100%;
  height: 100%;
}

.header {
  padding: 25px 0px;
  background-color: #ffffff;
  position: fixed;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s ease;
}

.header-title-container {
  margin-left: 40px;
}

.thank-you-header-title {
  padding-top: 100px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #0d1136;
  font-size: 45px;
}

.header-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #0d1136;
  font-size: 45px;
}

.header-subtitle {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #77798c;
  font-size: 15px;
}

.login-btn,
.customize-btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 158, 127);
  width: auto;
  font-size: 15px;
  color: #fff;
  font-family: Lato, sans-serif;
  font-weight: 700;
  padding-top: 0px;
  padding-bottom: 0px;
  box-sizing: border-box;
  height: 38px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: all 0.3s ease 0s;
  border-radius: 6px;
}

.reset-all-btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #8b0000;
  width: auto;
  font-size: 15px;
  color: #fff;
  font-family: Lato, sans-serif;
  font-weight: 700;
  padding-top: 0px;
  padding-bottom: 0px;
  box-sizing: border-box;
  height: 38px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: all 0.3s ease 0s;
  border-radius: 6px;
}

.settings-btn {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 30px;
}
.settings-btn.active {
  color: #909090;
}

.header-user {
  margin-right: 40px;
}

.left-nav {
  width: 240px;
  background-color: #ffffff;
  position: absolute;
  top: 120px;
  bottom: 0px;
  left: 0px;
  padding: 30px;
}

.left-nav-item {
  height: 50px;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left-nav-item:hover {
  cursor: pointer;
  color: #009e7f;
}

.selected {
  color: #009e7f;
}

.logout-button {
  font-family: 'Lato', sans-serif;
  position: absolute;
  bottom: 40px;
  left: 30px;
}

.logout-button:hover {
  cursor: pointer;
  color: #009e7f;
}

.category-main-containter {
  position: absolute;
  top: 120px;
  right: 0px;
  bottom: 0px;
  background-color: #f7f7f7;
  width: calc(100% - 240px);
  overflow: scroll;
  padding: 15px 30px;
}

.product-card {
  width: 300px;
  background-color: #009e7f;
  float: left;
  margin: 0px 15px 30px 15px;
  background-color: #fff;
  font-family: 'Lato', sans-serif;
  border-radius: 6px;
}

.product-card-container {
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
}

.product-card-image {
  max-width: 230px;
  max-height: 150px;
  margin: 0 auto;
  cursor: pointer;
}

.product-card-name {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #0d1136;
  margin: 20px 0 7px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
}

.product-card-initial-price {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #fbb979;
  font-style: italic;
  padding: 0 5px;
  overflow: hidden;
  position: absolute;
  top: -20px;
  left: -4px;
  text-decoration: line-through;
}

.product-card-brand {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  color: #fbb979;
  margin: 7px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-card-unit {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #77798c;
}

.product-card-footer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-card-price-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-card-sale-price {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #009e7f;
}

.product-card-cart-btn {
  border: 2px solid #f7f7f7;
  border-radius: 18px;
  height: 36px;
  padding-left: 17px;
  padding-right: 17px;
  font-size: 13px;
  font-weight: 700;
  color: #009e7f;
}

.product-card-cart-btn:hover {
  background-color: #009e7f;
  border: 2px solid #009e7f;
  color: #ffffff;
  cursor: pointer;
}

.product-card-cart-btn:focus {
  outline: none;
}

.product-card-cart-btn-disabled {
  background-color: #f7f7f7;
  border: 2px solid #f7f7f7;
  color: #cacaca;
}

.product-card-cart-btn-disabled:hover {
  background-color: #f7f7f7;
  border: 2px solid #f7f7f7;
  color: #cacaca;
  cursor: not-allowed;
}

.product-cart {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #009e7f;
  padding: 0;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 21px 36px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -46px;
  z-index: 99;
  font-family: 'Lato', sans-serif;
}

.product-cart.disabled {
  cursor: not-allowed;
}

.product-cart-total-items {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
}

.product-cart-price-box {
  width: auto;
  height: 35px;
  min-width: 80px;
  overflow: hidden;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #009e7f;
  margin: 0 10px 10px;
}

.login-page,
.customize-page {
  /* background-color: #009E7F; */
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.login-header-logo,
.customize-header-logo {
  padding: 100px 0px 50px 0px;
  height: 130px;
}

.login-card,
.customize-card {
  width: 300px;
  background-color: #009e7f;
  padding: 30px;
  margin-top: 60px;
  background-color: #fff;
  font-family: 'Lato', sans-serif;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-field-name {
  width: 100%;
}

.text-field-email {
  width: 100%;
  margin-top: 20px !important;
}

.join-btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 158, 127);
  width: auto;
  font-size: 15px;
  color: #fff;
  font-family: Lato, sans-serif;
  font-weight: 700;
  padding-top: 0px;
  padding-bottom: 0px;
  box-sizing: border-box;
  height: 38px;
  padding-left: 45px;
  padding-right: 45px;
  text-decoration: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: all 0.3s ease 0s;
  border-radius: 6px;
  margin-top: 40px;
}

.cart-modal-header {
  padding: 15px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
  font-family: 'Lato', sans-serif;
  margin: 0;
}

.cart-modal-header-items-count {
  display: inline-flex;
  align-items: center;
  color: #009e7f;
}

.card-modal-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.cart-modal-item-container {
  border-bottom: 1px solid #f7f7f7;
  margin-bottom: 0;
  width: 100%;
  padding: 15px 25px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
}

.cart-modal-item-image-container {
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 15px;
  flex-shrink: 0;
}

.cart-modal-item-image {
  width: 60px;
  max-height: 60px;
}

.cart-modal-item-info-name {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #0d1136;
  margin-bottom: 10px;
}

.cart-modal-item-info-price {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #009e7f;
  margin-bottom: 10px;
  margin: 0;
}

.cart-modal-item-info-weight {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #77798c;
  margin: 0;
}

.card-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.card-modal-footer-voucher-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.card-modal-footer-voucher {
  width: 100%;
  padding: 0 15px;
  flex-direction: column;
  padding-right: 22px;
}

.card-modal-footer-voucher-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-right: 5px;
  border-radius: 6px;
  background-color: #ffffff;
  overflow: hidden;
  border: 1px solid #ededed;
}

.card-modal-footer-voucher-input-container {
  width: 100%;
}

.card-modal-footer-voucher-input-wrapper {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-modal-footer-voucher-input {
  height: 100%;
  flex-grow: 1;
  font-size: 15px;
  color: #77798c;
  border: none;
  border-radius: 0;
  padding: 0 20px;
  background-color: transparent;
  margin-right: 0;
  text-transform: uppercase;
}

.card-modal-footer-voucher-input:focus {
  outline: none;
}

.card-modal-footer-voucher-apply-btn {
  height: calc(100% - 10px);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #009e7f;
  width: auto;
  color: #ffffff;
  border-radius: 6px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
  border: 0;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.card-modal-footer-voucher-apply-btn:focus {
  outline: none;
}

.card-modal-footer-voucher-apply-btn:disabled {
  color: #909090;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  cursor: not-allowed;
}

.card-modal-footer-voucher-apply-btn-text {
  padding-left: 4px;
  padding-right: 4px;
  white-space: nowrap;
}

.card-modal-footer-checkout-btn {
  height: 48px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #009e7f;
  padding: 0;
  border-radius: 48px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 15px;
}

.card-modal-footer-checkout-btn-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  padding-right: 10px;
  text-decoration: none;
}

.card-modal-footer-checkout-amount {
  width: auto;
  height: 44px;
  padding: 0 30px;
  overflow: hidden;
  border-radius: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #009e7f;
  margin-right: 2px;
}

.product-modal-card {
  background-color: rgb(255, 255, 255);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 100%;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 40px;
  border-radius: 6px;
  overflow: hidden;
}

.product-modal {
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

.product-modal-preview {
  width: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 30px 60px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.product-modal-preview-image {
  width: 330px;
  max-height: 330px;
}

.product-modal-details-container {
  max-width: 50%;
  flex: 0 0 50%;
  border-left: 1px solid rgb(243, 243, 243);
}

.product-modal-details-wrapper {
  padding: 50px;
}

.product-modal-details-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.product-modal-name {
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 700;
  color: rgb(13, 17, 54);
  line-height: 1.5;
  display: flex;
  margin: 0;
}

.product-modal-details-price-wrapper {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 25px;
  line-height: 31px;
}

.product-modal-details-price {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: rgb(0, 158, 127);
}

.product-modal-details-unit {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(119, 121, 140);
}

.product-modal-details-description {
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgb(66, 69, 97);
  line-height: 2;
  margin-top: 30px;
}

.product-modal-details-brand {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  color: #fbb979;
  margin: 7px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-modal-action-container {
  display: flex;
  flex-direction: column;
}

.product-modal-action-wrapper {
  margin-top: 60px;
}

.product-modal-action-cart-btn {
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: rgb(0, 158, 127);
  height: 36px;
  border-radius: 4rem;
}

.product-modal-action-cart-btn-icon {
  margin-right: 5px;
}

.product-modal-action-cart-text {
  padding-left: 4px;
  padding-right: 4px;
  white-space: nowrap;
}

.hidden {
  visibility: hidden !important;
  display: none !important;
}

.analytics-js-dropzone {
  border: 1px dashed #929292;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 15px 8px 0px;
}

.analytics-js-dropzone:focus {
  outline: none;
}

.analytics-js-dropzone:hover {
  cursor: pointer;
}

.cart-modal-item-remove {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ff5b5b;
}

.cart-modal-item-info-container {
  position: relative;
  padding-right: 40px;
}

.cart-modal-item-remove-btn {
  margin-left: 10px;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid #ff5b5b;
  color: #ff5b5b;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cart-modal-item-remove-btn:hover {
  background-color: #ff5b5b;
  color: #fff;
}

.cart-modal-item-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
}

.product-card-actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.product-card-wishlist-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #009e7f;
  color: #009e7f;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.product-card-wishlist-btn:hover {
  background-color: #009e7f;
  color: #fff;
}

.promotion-banner {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.promotion-banner:hover {
  transform: translateY(-2px);
}

.promotion-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.promotion-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  color: white;
}

.promotion-content h3 {
  margin: 0 0 8px 0;
  font-size: 24px;
}

.promotion-content p {
  margin: 0;
  font-size: 16px;
}
